// seo.service.ts
import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(private titleService: Title, private metaService: Meta) { }

    updateMetaTags(productName: string, CatalogueNumber: string, CategoryName: string) {
        this.titleService.setTitle(`${productName} - Azolechem`);
        this.metaService.updateTag({ name: 'description', content: `Looking for best quality ${productName}. no look further than Azolechem we offer wide range of chemical click here.` });
        const sanitizedProductName = productName.replace(/%20/g, '-').replace(/\s+/g, '-');
        const canonicalUrl = `https://azolechem.com/Product/${sanitizedProductName}`;
        this.setCanonicalLink(canonicalUrl);
        this.metaService.updateTag({
            name: 'keywords',
            content: `${productName} , ${CategoryName}`
        });
    }
    updateMetaTags1(productName: string, productDescription: string, Title: string, catalogueNumber: string, Canonical: string, Keywords: string) {
        // Set the concise Title for the meta tag (SEO)
        this.titleService.setTitle(Title); // This will be the SEO-friendly title for the browser tab

        // Construct the full title for scrolling (this includes the product name and description)
        const fullTitle = `${productName} - ${Title} - ${productDescription}`;

        // Update the meta description for SEO (use product description)
        this.metaService.updateTag({
            name: 'description',
            content: productDescription
        });
        this.metaService.updateTag({
            name: 'keywords',
            content: Keywords
        });
        const canonicalUrl = `${Canonical}`;
        this.setCanonicalLink(canonicalUrl);


        // Start the scrolling effect with the fullTitle in the browser tab
        this.startScrollingTitle(fullTitle); // Scrolls the full title in the tab
    }

    // Function to implement scrolling effect in the title
    startScrollingTitle(title: string) {
        let index = 0;
        const scrollSpeed = 300; // Adjust scroll speed (ms)

        const scrollTitle = () => {
            // Set the tab's title with the full title for scrolling effect
            const displayTitle = title.substring(index) + ' | ' + title.substring(0, index);
            //document.title = displayTitle; // Directly manipulate the browser tab's title for scrolling effect

            // Update the index for the next scroll
            index = (index + 1) % title.length; // Loop back when the end is reached
        };

        // Start the scrolling effect
        setInterval(scrollTitle, scrollSpeed);
    }


    setCanonicalLink(canonicalUrl: string) {
        // Remove any existing canonical link
        const existingLink = document.querySelector("link[rel='canonical']");
        if (existingLink) {
            existingLink.remove();
        }

        // Create a new canonical link element
        const link: HTMLLinkElement = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', canonicalUrl);

        // Append the new link to the head section
        document.head.appendChild(link);
    }

    updateMetaTags2(productName: string, CatalogueNumber: string) {
        this.titleService.setTitle(`${productName} - Azolechem`);
        this.metaService.updateTag({ name: 'description', content: 'info' });
        const sanitizedProductName = productName.replace(/%20/g, '-').replace(/\s+/g, '-');
        const canonicalUrl = `https://azolechem.com/${sanitizedProductName}`;
        this.setCanonicalLink(canonicalUrl);
    }
}





    // Method to dynamically update meta tags, canonical, keywords, and SEO links
    //updateMetaTags(productName: string, catalogueNumber: string, url: string, keywords: string, description: string) {
    //    // Set the page title dynamically
    //    this.titleService.setTitle(`${productName} - Azolechem`);

    //    // Update meta description
    //    this.metaService.updateTag({ name: 'description', content: description });

    //    // Update meta keywords
    //    this.metaService.updateTag({ name: 'keywords', content: keywords });

    //    // Update canonical URL
    //    const link: HTMLLinkElement = document.createElement('link');
    //    link.setAttribute('rel', 'canonical');
    //    link.setAttribute('href', url);
    //    document.head.appendChild(link);
    ////}






