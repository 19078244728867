import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-seocontent',
  templateUrl: './seocontent.component.html',
  styleUrls: ['./seocontent.component.scss']
})

export class SeocontentComponent {
    ProductId: any;
    SeoLinkContent: any = [];
    SeoLinkContent123: any = [];
    HomeUrl: any;
    navlink: any;
    loginDet: any;

    constructor(public generalservice: GeneralService, private activateroute: ActivatedRoute, public http: HttpClient) {
        this.ProductId = this.activateroute.snapshot.paramMap.get("Seolinkname");

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
           this.HomeUrl = data.Webservice;
          //  this.HomeUrl = 'http://localhost:57391/'
        });

    }

    ngOnInit() {
        this.seocontent();
    }

    seocontent() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.ProductId);
        var url = "api/Azole/Get_SeoLinkData_BasedOnID";
        this.generalservice.PostData(url, uploadfile).then((data: any) => {
            debugger
            this.SeoLinkContent123 = data;
            this.navlink = this.SeoLinkContent123[0].RedirectProdUrl
            this.SeoLinkContent = data.map(item => {
                // Decode the TitleContent to display HTML tags properly
                let decodedContent = decodeURIComponent(item.TitleContent);
                return {
                    TitleContent: this.highlightDynamicContent(decodedContent)
                };
            });
        });
    }

    highlightDynamicContent(content: string): string {
        var tempElement = document.createElement("div");
        tempElement.innerHTML = content;

        // Example: Highlight <h1>, <h2>, <strong> tags, or other specific content
        const tagsToHighlight = ['h1', 'h2', 'strong', 'b', 'br']; // Tags you want to highlight
        tagsToHighlight.forEach(tag => {
            // Find elements with the given tag name and wrap them with a <mark> tag
            let elements = tempElement.getElementsByTagName(tag);
            Array.from(elements).forEach((el) => {
                el.innerHTML = `<mark>${el.innerHTML}</mark>`;
            });
        });

        // Convert back to HTML string
        return tempElement.innerHTML;
    }

        navigate(link: string) {
            window.location.href = link;
        }
    


}
