import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SeoService } from '../../seo.service'
@Component({
  selector: 'app-seocontent',
  templateUrl: './seocontent.component.html',
  styleUrls: ['./seocontent.component.scss']
})

export class SeocontentComponent {
    ProductId: any;
    SeoLinkContent: any = [];
    SeoLinkContent123: any = [];
    HomeUrl: any;
    navlink: any;
    loginDet: any;

    constructor(public generalservice: GeneralService, private activateroute: ActivatedRoute, public http: HttpClient, private router: Router, private seoService: SeoService) {
        this.ProductId = this.activateroute.snapshot.paramMap.get("Seolinkname");

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
           this.HomeUrl = data.Webservice;
          //  this.HomeUrl = 'http://localhost:57391/'
        });

    }

    ngOnInit() {
        this.seocontent();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                    // Reload the page
                window.location.reload();
            }
        });
    }

    seocontent() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.ProductId);
        var url = "api/Azole/Get_SeoLinkData_BasedOnID";
        this.generalservice.PostData(url, uploadfile).then((data: any) => {
            debugger
            this.SeoLinkContent123 = data;
            this.updateSeo1(this.SeoLinkContent123)
            this.navlink = this.SeoLinkContent123[0].RedirectProdUrl
            this.SeoLinkContent = data.map(item => {
                // Decode the TitleContent to display HTML tags properly
                let decodedContent = decodeURIComponent(item.TitleContent);
                return {
                    TitleContent: this.highlightDynamicContent(decodedContent)
                };
            });
        });
    }

    highlightDynamicContent(content: string): string {
        var tempElement = document.createElement("div");
        tempElement.innerHTML = content;

        // Example: Highlight <h1>, <h2>, <strong> tags, or other specific content
        const tagsToHighlight = [ 'strong']; // Tags you want to highlight
        tagsToHighlight.forEach(tag => {
            // Find elements with the given tag name and wrap them with a <mark> tag
            let elements = tempElement.getElementsByTagName(tag);
            Array.from(elements).forEach((el) => {
                el.innerHTML = `<mark>${el.innerHTML}</mark>`;
            });
        });

        // Convert back to HTML string
        return tempElement.innerHTML;
    }

        navigate(link: string) {
            window.location.href = link;
        }
    updateSeo1(product: any) {
        debugger;
        const productName = product[0]?.Metatag || ''; // Retrieve the product's meta title
        const productDescription = product[0]?.MetaDesc || ''; // Retrieve the product's meta description
        const catalogueNumber = product[0]?.Catalogue_Number || '';
        const Title = product[0]?.Title || '';
        const Canonical = product[0]?.CanonicalLink || '';
        const Keyword = product[0]?.Keywords || '';
        // Retrieve the product's catalogue number
        this.seoService.updateMetaTags1(productName, productDescription, Title, catalogueNumber, Canonical,Keyword);
    }

    seolink(Seolinkname: string) {
        debugger;

        // Convert to lowercase, replace spaces and unwanted characters with hyphens, while keeping commas
        const formattedSeolinkname = Seolinkname
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/[()]/g, '') // Remove parentheses
            .replace(/[^a-z0-9-,]/g, ''); // Remove any characters that are not alphanumeric, hyphens, or commas

        // Navigate to the formatted URL
        this.router.navigate(['/products', formattedSeolinkname]).then(() => {
            window.location.reload();
        });
    }

    


}
