import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { SeoService } from '../../seo.service';
@Component({
  selector: 'app-home-five',
  templateUrl: './home-five.component.html',
  styleUrls: ['./home-five.component.scss']
})
export class HomeFiveComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, private seoService: SeoService,) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

    ngOnInit() {
        this.seoService.updateMetaTags2('Certificates', '');
  }

}
