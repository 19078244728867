// seo.service.ts
import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(private titleService: Title, private metaService: Meta) { }

    updateMetaTags(productName: string, CatalogueNumber: string) {
        this.titleService.setTitle(`${productName} - Azolechem`);
        this.metaService.updateTag({ name: 'description', content: 'info' });
    }




    // Method to dynamically update meta tags, canonical, keywords, and SEO links
    //updateMetaTags(productName: string, catalogueNumber: string, url: string, keywords: string, description: string) {
    //    // Set the page title dynamically
    //    this.titleService.setTitle(`${productName} - Azolechem`);

    //    // Update meta description
    //    this.metaService.updateTag({ name: 'description', content: description });

    //    // Update meta keywords
    //    this.metaService.updateTag({ name: 'keywords', content: keywords });

    //    // Update canonical URL
    //    const link: HTMLLinkElement = document.createElement('link');
    //    link.setAttribute('rel', 'canonical');
    //    link.setAttribute('href', url);
    //    document.head.appendChild(link);
    ////}





}
