import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { CartService } from '../../Services/cart.service'
declare var $: any;
import { SeoService } from '../../seo.service'
@Component({
    selector: 'app-proced-to-pay',
    templateUrl: './proced-to-pay.component.html',
    styleUrls: ['./proced-to-pay.component.scss']
})
export class ProcedToPayComponent {
    salesdata: any;
    salesinfo: any;
    totalPrice: any;
    datetime: any;
    OrderDetails: any;
    Id: any;
    deliveryCharge: any;
    offerPrice: any = 0;
    GstPrice: any;
    offerDetails: any;
    offerCode: any;
    WishListitemmsforloginusers: any = [];
    cartIndex: any;
    n: any;
    val: number;
    arr: any = [];
    PaymentType: any;
    addressId: any;
    currentCartItems: any = [];
    customerCartItems: any = [];
    loginStatus: boolean;
    //addressDetails: any;
    addressDetails1: any;
    LoginDetails: any;
    itemSize: any;
    ItemId: any;
    seletedItemforWishlist: any = [];
    wishListItems: any = [];
    subTotal: any = 0;
    HomeURL: any;
    cartItems: any;
    Quantity: any;
    offersdata: any;
    offerId: any;
    alert: any;
    //getAllAdminOrders: any;
    plus: boolean = true;
    TransactionStatus: any;
    admin: any;
    loginDetails: any;
    loginDet: any;
    AID: any;
    orderno: any;
    packageitems: any;
    Packagedata: any;
    packagesarr: any;
    Itemdata: any;
    TTprice: any; TaxnId: any;
    Grandtotal = 0;
    addressDetails: any[] = []; // Assuming addressDetails is an array
    selectedAddresses: any[] = [];
    address1: any; // Selected billing address
    address2: any; // Selected delivery address
    isBillingAddressSelected: boolean = false;
    isDeliveryAddressSelected: boolean = false;
    useBillingAddress: boolean = false; // Track state of "Use billing address for delivery" checkbox
    useDeliveryAddress: boolean = false;
    isUseBillingAddressCheckboxVisible: boolean = true;
    isUseDeliveryAddressCheckboxVisible: boolean = true;
    token: any;
    zohobook_addressId: any;
    selectaddresstypyid: any;
    billing_address_id: any;
    shipping_address_id: any;
    zohobook_DeliveryaddressId: any;
    zohobook_billing_addressId: any;
    LineItems: any;
    LItems: any;
    lineitems: any;
    profiledata: any;
    discountprice: any = 0;
    APPdisprice: any = 0;
    PoDate: any; PoNumber: any; PoEmail: any; Comments: any;

    constructor(public http: HttpClient, public cartService: CartService, private seoService: SeoService,
       
        public generalService: GeneralService, public router: Router) {
        debugger
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)

        this.PoEmail = this.loginDetails[0] ?.EMailID || '';

        this.LineItems = localStorage.getItem("LineItems");
        this.LItems = JSON.parse(this.LineItems)
        this.Packagedata = localStorage.getItem("Packagesarr");
        this.packagesarr = JSON.parse(this.Packagedata)

        const today = new Date();
        this.PoDate = today.toISOString().split('T')[0];

        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            this.subTotal += (a.Price * 1) * (a.Quantity)
            // Assuming Price is a number
            const gstTax = this.subTotal * (a.GSTTAX / 100);
            this.Grandtotal = this.subTotal + gstTax;
        })
        // Assuming Price is a number
    

        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeURL = data.Webservice;
        });
    }

    ngOnInit(): void {
        this.seoService.updateMetaTags2('Procced To Buy', '');
        this.getprofile();
        if (this.loginDetails) {
            this.loginStatus = true;
            this.getCustomerAddress();
        }
        else {
            this.loginStatus = false;
        }

    }



    GotoAddress() {
        this.router.navigate(['/Useraddress']);
    }

    getprofile() {
        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                RegId: this.loginDetails[0].RegId,
                CreditLimit: 0,
            });

            var UploadFile = new FormData();
            // const phone1 = this.LoginDetails ? this.LoginDetails[0].Phone1 : '';
            // const password = this.LoginDetails ? this.LoginDetails[0].PWD : '';

            // Encode the password to Base64
            //const encodedPassword = btoa(password);

            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", "6");

            var url = this.HomeURL + "api/Azole/Azl_Registation_GAUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {

                this.profiledata = data;
                
              
                // this.LoginDetails = this.profiledata;



            },
                err => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }




    getCustomerAddress() {
         

        let formData: FormData = new FormData();
        formData.append('Param', this.loginDetails[0].RegId);
        var url = "api/Azole/Get_addresses_based_Userid";

        this.generalService.PostData(url, formData).then((data: any) => {
            if (data != "") {
                this.addressDetails = data;
                this.plus = false
            }
        });
    }






    sendMailToAdmin(val) {
        

        this.addressDetails1 = []
        this.addressDetails1 = this.addressDetails
        let arr = [];
        arr.push({
            OrderID: this.addressDetails1[0].OrderID,
            FarmerName: this.addressDetails1[0].FarmerName,
            MobileNo: this.addressDetails1[0].MobileNo,
            Area: this.addressDetails1[0].Area,
            City: this.addressDetails1[0].City,
            Email: this.addressDetails1[0].Email,
            //ProductName: this.orderDetails[0].ProductName,

        })
        var UploadFile = new FormData();
        var url = "api/Products/Assginoredr_aftersend_mailToAdmin"

        UploadFile.append("Xml", JSON.stringify(arr));
        UploadFile.append("flag", val);
        this.generalService.PostData(url, UploadFile).then((data: any) => {
           
        })
    }





    /*end*/
    PlaceOrder1() {
        debugger
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            if (!this.address1 || !this.address2) {
                this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please select both addresses.', '');
                return;
            }
            if (!this.PoNumber) {
                this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please Enter PO_Number Value.', '');
                return;
            }

            if (!this.profiledata[0].CRMID) {
                this.generalService.Alertwarning('<img src="../../../assets/icons/icons8-warning.gif" />', 'CRM ID is missing. Order cannot be placed. Please Contact Admin', '');
                return;
            }

            if (this.profiledata[0].ApprovalStatus != 'Approved') {
                this.generalService.Alertwarning('<img src="../../../assets/icons/icons8-warning.gif" />', 'Sign up is not approved by AZOELCHEM.', '');
                return;
            }
            this.arr = [];
            this.n = ""
            var d = new Date();
            this.n = d.getTime();

            for (var i = 0; i < this.cartItems.length; i++) {
                this.arr.push({
                    PersonId: this.loginDetails[0].RegId,

                    ProductId: this.cartItems[i].Productid,
                    Quantity: this.cartItems[i].Quantity,


                    Address: this.address1,
                    AddressDelivery: this.address2,
                    PoNumber: this.PoNumber,
                    PoDate: this.PoDate,
                    PoEmail: this.PoEmail,
                    Comments: this.Comments,

                    ZBookingID: this.cartItems[i].ZBookingID,
                    Record_Image: this.cartItems[i].Record_Image,
                    CatalogNo: this.cartItems[i].CatalogNo,
                    Price: this.cartItems[i].Price,
                    PackSize: this.cartItems[i].PackSize,
                    HSNCode: this.cartItems[i].HSNCode,
                    CAS: this.cartItems[i].CAS,
                    ChemicalName: this.cartItems[i].ProductName,
                    GSTTAX: this.cartItems[i].GSTTAX,
                    ItemCode: this.cartItems[i].ItemCode,
                    PackageId: this.cartItems[i].PackageId,
                    TotalPrice: localStorage.Grantotal,
                    //TotalPrice: this.discountprice,
                    Discount :this.loginDetails[0].Discount ?? 0,
                 
                    CompanyCode: this.loginDetails[0].CompanyCode,
                    CreatedBy: this.loginDetails[0].RegID,
                    StatusID: 1,
                    Zoho_Book_AddressID: this.addressDetails[0].ZohoBookAddressID



                })
            }

            //this.RedirectToPaymentGateway(this.arr, this.n)  paymentgetway commented
            let formData: FormData = new FormData();
            formData.append('Param', JSON.stringify(this.arr));

            var url = this.HomeURL + "api/Azole/Insert_Customer_Orders";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, formData, { headers }).subscribe(
                (data: any) => {
                     

                    this.orderno = data

                    //if (data && data) {
                    //    this.generalService.ShowAlert('OrderNo:' + this.orderno, 'Your Order is placed.', 'Success');
                    //    this.subTotal = 0
                    if (data.Table2[0].MSG && data.Table2[0].MSG) {
                        //this.generalService.ShowAlert('OrderNo:' + this.orderno.Table2[0].MSG, 'Your Order is placed.', 'Success');
                         this.generalService.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', 'Your order has been placed  Successfully.', '');
                        this.Sales_orders();
                        this.router.navigate(['/MyOrders']);
                        localStorage.removeItem('Packagesarr');
                        localStorage.removeItem('cartItems');
                        localStorage.removeItem('Grantotal');
                        localStorage.removeItem('LineItems');
                        this.cartService.removeAllcart();
                        this.cartItems = {};

                        this.cancel()
                        this.val = 1
                        this.sendMailToAdmin(this.val)


                    }
                });


        });
    }

    Get_Od() {
        

        let formData: FormData = new FormData();
        formData.append('Param', this.orderno.Table2[0].MSG);
        var url = "api/Azole/Getorderdetails_basedon_OID";

        this.generalService.PostData(url, formData).then((data: any) => {
            
            this.salesdata = data

            this.Update_sales()

        });
    }
    Update_sales() {
        
        this.arr = []
        this.arr.push({
            OrderID: this.salesdata[0].OrderID,
            payment_terms_label: this.salesinfo.salesorder.payment_terms_label,
            Salesorder_Id: this.salesinfo.salesorder.salesorder_id
        });
        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(this.arr));
        var url = "api/Azole/update_salesid_paymentlable_based_on_ODID";

        this.generalService.PostData(url, formData).then((data: any) => {
             
            this.salesdata = data



        });
    }

    cancel() {
        $('#myModal').modal('hide');
    }

    getSelectedbillingAddress(addressId: any, isChecked: boolean, categoryID: number, Zoho_Book_AddressID: any) {
        debugger

        this.selectaddresstypyid = categoryID
        if (isChecked) {
            console.log("Selected Address ID:", addressId);
            if (categoryID === 1) {
                this.address1 = addressId;
                this.zohobook_billing_addressId = Zoho_Book_AddressID
                localStorage.setItem("billinaddress", this.zohobook_billing_addressId)
                this.isBillingAddressSelected = true;
            }
        } else {
            if (categoryID === 1 && this.address1 === addressId) {
                this.address1 = null;
                this.isBillingAddressSelected = false;
                this.zohobook_billing_addressId = Zoho_Book_AddressID
                localStorage.setItem("billinaddress", this.zohobook_billing_addressId)

            }
        }
    }
    getSelectedDeliveryAddress(addressId: any, isChecked: boolean, categoryID: number, Zoho_Book_AddressID: any) {
        debugger

        this.selectaddresstypyid = categoryID
        if (isChecked) {
            console.log("Selected Address ID:", addressId);
            if (categoryID === 2) {
                this.address2 = addressId;
                this.zohobook_DeliveryaddressId = Zoho_Book_AddressID
                localStorage.setItem("delliveryaddress", this.zohobook_DeliveryaddressId)

                this.isDeliveryAddressSelected = true;
            }
        } else {
            if (categoryID === 2 && this.address2 === addressId) {
                this.address2 = null;
                this.isDeliveryAddressSelected = false;
                this.zohobook_DeliveryaddressId = Zoho_Book_AddressID
                localStorage.setItem("delliveryaddress", this.zohobook_DeliveryaddressId)
            }
        }
    }

    useBillingAddressForDelivery(useBillingAddress: boolean) {
        debugger
        if (useBillingAddress && this.isBillingAddressSelected) {
            this.address2 = this.address1;
            this.zohobook_DeliveryaddressId = this.zohobook_billing_addressId
        } else {
            this.address2 = null;
        }

    }

    useDeliveryAddressForBilling(useDeliveryAddress: boolean) {
        debugger
        if (useDeliveryAddress && this.isDeliveryAddressSelected) {
            this.address1 = this.address2;
            this.zohobook_billing_addressId = this.zohobook_DeliveryaddressId
        } else {
            this.address1 = null;
        }
    }

    Sales_orders() {
     debugger   




        this.datetime = new Date();
        this.n = this.datetime.toISOString().split('T')[0];

        let formData: FormData = new FormData();
        formData.append('date', this.n);
        formData.append('Per_BOOks_ID', this.loginDetails[0].ZOHO_Books_ID);//change here varient itemid    
        formData.append('PoNumber', this.PoNumber);//change here PoNumber    new code
        formData.append('shippingCharge', '0');
        formData.append('LineItems', JSON.stringify(this.LItems));
        formData.append('OrderID', this.orderno.Table2[0].MSG);
        formData.append('paymentTermsLabel', 'Net 30');
        formData.append('shipmentDate', this.n);
        formData.append('billing_address_id', this.zohobook_billing_addressId);
        formData.append('shipping_address_id', this.zohobook_DeliveryaddressId);

        var url = "api/Azole/After_Booking_SalesOrder";

        this.generalService.PostData(url, formData).then((data: any) => {
           
            this.salesinfo = data
            if (this.salesinfo.message == "Sales Order has been created.") {
                this.generalService.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', 'Your order has been placed  Successfully.', '');

            }
            this.Get_Od();


        });
    }


    getPaymentType(pType: string) {
        this.PaymentType = pType
    }

    selectmode() {
       
        $('#myModal').modal('show');
    }



    navigatingToProduct(data) {
        var prodName = "";
        var str = data.split(" ")//spliting the product name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.router.navigate(['/Product/' + prodName]); ///sandhya
    }







}
