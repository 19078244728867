import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { CartService } from '../../Services/cart.service'
import { SeoService } from '../../seo.service'
@Component({
    selector: 'app-products-details',

    templateUrl: './products-details.component.html',
    styleUrls: ['./products-details.component.scss']
})
export class ProductsDetailsComponent {
    public form: FormGroup;
    //displayCOADropdown: boolean = false;

    arr: any = [];
    discus: any;
    base64path: any;
    ProductName: any;
    Name: any;
    LoginDetails: any;
    Packsdeta: any;
    HomeURL: any;
    productDetails: any;
    ProductId: any;
    CategoryID: any;
    Accidental_Release_Mesaures: any;
    Stock_Variant: any;
    Books_ID: any;
    CAS_No: any;
    Catalog_No: any;
    Pick_List_3: any;
    Catalogue_Number: any;
    Density: any;
    Disposal_Considerations: any;
    Email: any;
    Exposure_Guidelines: any;
    First_Aid_Measures: any;
    Grade: any;
    Handling_And_Storage: any;
    logedinUser: any;
    quantity: any;
    HSN: any;
    loginDet: any;
    loginDetails: any;
    Record_Image: any; Owner: any; Molecular_Formula: any; Molecular_Weight: any;
    NLT_Purity: any; Other_Information: any; Personal_Protection: any; Price: any;
    Purity: any; Regulatory_Information: any; Shelf_Time: any; Stability_And_Reactivity: any;
    Storage_Temperature: any; Subform_1: any; Synonyms: any; CRMID: any;
    Item_Code: any; Gst: any; Weight_Kg: any; Variants: any;
    quantities: any[] = []
    Cartimesforloginusers: any[];
    selectedItemforCart: any = []
    category1: any;
    activeCategoryID: any; activeCategoryID1: any;
    cartItems: any; subTotal: any = 0; totalPrice: any; Gstper: any; gtprice: any; Grandtotal = 0; Gamount: any; gstprice: any;
    items: any;
    Quantity: any;
    Quantity1: any;
    Quantityinc: any;
    flag: number;
    UOM: any;
    Comments: any;
    showCustomEnquiry: boolean = false;
    uomlist: any;
    deptList: any[] = [];
    UOMID: any;
    baseurl: string;
    bulkOrderData: any;
    pdf: any;
    relativeFilePath: any;
    HomeUrl: any;
    token: any;
    searchInputValue: string;
    COApdf: any[] = []; // Assuming COApdf contains all COA data
    COApdfFiltered: any[] = []; // Initialize COApdfFiltered as an empty array
    displayCOADropdown: boolean = false;
    favlist: any; FavorateItem: boolean = false;
    stockFetched: boolean = false;
    Surl: string;
    loading3: boolean = false;
    BatchNo: any;
    item: any;
    PackSize: string; // Define PackSize property
    PackName: string; // Define PackName property
    ENQ_ID: any;
    Stockdata: any;
    sadata: any;
    lineitems: any[];
    isButtonDisabled: boolean = false;
    Sample_COA_Record_ID: any;
    profiledata: any;
    packageprice: any;
    dic: any;
    PRCRMID: any;
    MSDS_Status: any;
    CategoryName: any;
    decodedProductName: string;
    constructor(public CartService: CartService, private seoService: SeoService
        , private viewportScroller: ViewportScroller, public activeroute: ActivatedRoute, public router: Router, public generalService: GeneralService, public http: HttpClient, public fb: FormBuilder,) {
        for (let i = 1; i <= 10; i++) {
            this.quantities.push(i);
        }
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'

        });
        debugger
        this.ProductName = this.activeroute.snapshot.paramMap.get("Productdetails");
       // this.ProductName = this.activeroute.snapshot.paramMap.get('Productdetails').replace(/\_/g, " ").trim();
        this.decodedProductName = decodeURIComponent(this.ProductName );
        this.PRCRMID = localStorage.getItem('CRMID')
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)

       
        this.CartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res
            this.items = this.cartItems

        })
        this.cartItems.forEach((item: any) => {
            
            this.subTotal = (item.Price * 1) * (item.Quantity); // Assuming Price is a number
            const gstTax = this.subTotal * (item.GSTTAX / 100);
            this.Grandtotal += this.subTotal + gstTax;
            this.Gamount = this.Grandtotal
            localStorage.setItem('Grantotal', this.Gamount);
        });

        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.GetProductDetailsbasedonProductName()
            }
            else {
                //this.cartService.setRelatedproductId(res[0].Productid)
               // this.ProductName = res[0].productname;
                this.GetProductDetailsbasedonProductName()

            }
        })
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });

        this.form = this.fb.group({
            UOM: ['', Validators.required] // Set up form control for Categoryname with required validation
        });

       // this.GetFav();
    }

    ngOnInit(): void {
        this.GetUOM();
        this.GetFav();
        this.getprofile()
        this.activeroute.queryParams.subscribe(params => {

            const categoryID = params['categoryID'];
            if (categoryID) {
                this.GetProductsByCategory(categoryID);
            }
            const CategoryID = params['CategoryID'];
            if (CategoryID) {
                this.GetProductDetailsbasedonProductName()
            }
        });

      
        setInterval(() => {
            // this.onRefresh(event);
            this.ProductName = this.activeroute.snapshot.paramMap.get("Productdetails");
           
        }, 1000);


        this.GetCOA();
        //this.sendCOARequest();
    }

    onRefresh(event) {

        setTimeout(() => {
            this.GetProductDetailsbasedonProductName();
            if (event !== null) {
                event.target.complete();
            }
        }, 1000)
    }


    getprofile() {
        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                RegId: this.loginDetails[0].RegId,
                CreditLimit: 0,
            });

            var UploadFile = new FormData();
      
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", "6");

            var url = this.HomeUrl + "api/Azole/Azl_Registation_GAUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {

                this.profiledata = data;

                this.discus = this.profiledata.filter(a => a.RegId == this.loginDetails[0].RegId)
             
            },
                err => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    GetProductDetailsbasedonProductName() {
        debugger      
        this.loading3 = true;
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.activeCategoryID1 = this.CategoryID;
            var UploadFile = new FormData()
            UploadFile.append("Param", this.ProductName )

            var url = this.HomeUrl + "api/Azole/Productdetails_basedon_productName";
            var accessToken = this.token;

          
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`
            });

         
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                   debugger
                    this.productDetails = data;
                    this.updateSeo(this.productDetails)
                    this.ProductId = this.productDetails[0].ProductId
                    this.GetPacks(this.productDetails[0].ProductId);
                    this.CategoryID = this.productDetails[0].CategoryID
                    this.Accidental_Release_Mesaures = this.productDetails[0].Accidental_Release_Mesaures
                    this.Stock_Variant = this.productDetails[0].Stock_Variant
                    this.Variants = this.productDetails[0].Variants
                    this.Books_ID = this.productDetails[0].Books_ID
                    this.Sample_COA_Record_ID = this.productDetails[0].Sample_COA_Record_ID
                    this.CAS_No = this.productDetails[0].CAS_No
                    this.Catalog_No = this.productDetails[0].Catalog_No
                    this.Catalogue_Number = this.productDetails[0].Catalogue_Number
                    this.Pick_List_3 = this.productDetails[0].Pick_List_3
                    this.Density = this.productDetails[0].Density
                    this.Disposal_Considerations = this.productDetails[0].Disposal_Considerations
                    this.Email = this.productDetails[0].Email
                    this.Exposure_Guidelines = this.productDetails[0].Exposure_Guidelines
                    this.First_Aid_Measures = this.productDetails[0].First_Aid_Measures
                    this.Grade = this.productDetails[0].Grade
                    this.Handling_And_Storage = this.productDetails[0].Handling_And_Storage
                    this.HSN = this.productDetails[0].HSN
                    this.Name = this.productDetails[0].Name
                    this.Record_Image = this.productDetails[0].Record_Image
                    this.Owner = this.productDetails[0].Owner
                    this.Molecular_Formula = this.productDetails[0].Molecular_Formula
                    this.Molecular_Weight = this.productDetails[0].Molecular_Weight
                    this.NLT_Purity = this.productDetails[0].NLT_Purity
                    this.Other_Information = this.productDetails[0].Other_Information
                    this.Personal_Protection = this.productDetails[0].Personal_Protection
                    this.Price = this.productDetails[0].Price
                    this.Purity = this.productDetails[0].Purity
                    this.Regulatory_Information = this.productDetails[0].Regulatory_Information
                    this.Shelf_Time = this.productDetails[0].Shelf_Time
                    this.Stability_And_Reactivity = this.productDetails[0].SheStability_And_Reactivitylf_Time
                    this.Storage_Temperature = this.productDetails[0].Storage_Temperature
                    this.Subform_1 = this.productDetails[0].Subform_1
                    this.Synonyms = this.productDetails[0].Synonyms
                    this.CRMID = this.productDetails[0].CRMID
                    this.MSDS_Status = this.productDetails[0].MSDS_Status
                    this.Item_Code = this.productDetails[0].Item_Code
                    this.Gst = this.productDetails[0].Gst
                    this.Weight_Kg = this.productDetails[0].Weight_Kg
                    this.Quantity = this.productDetails[0].Quantity
                    this.CategoryName = this.productDetails[0].CategoryName
                    this.MSDS_Status = this.productDetails[0].MSDS_Status
                    this.gtprice = this.Price;
                    this.searchInputValue = '';
                    this.Get_stocks(this.Books_ID)
                },
                (err) => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
            this.loading3 = false;
        });

    }
    private updateSeo(product: any) {
        debugger
        const productName = product[0].Name;
        const Catalogue_Number = product[0].Catalogue_Number;
        const CategoryName = product[0].CategoryName;
        this.seoService.updateMetaTags(productName, Catalogue_Number, CategoryName);
    }
    updatePrice() {
        this.Price = this.gtprice * this.Quantity;
    }

    logedin() {
        this.logedinUser = localStorage.getItem('TokenID');
        return this.logedinUser;
    }

    discountstotal(item) {
    
        
        let totalWithGST = (item.PackPrice * item.Quantity);

        // Calculate the discount amount
        let discountAmount = totalWithGST * this.discus[0].Discount / 100

        // Apply the discount to the total
        let totalWithDiscount = totalWithGST - discountAmount;

        return totalWithDiscount;


    }
    GetPacks(productid: any) {

        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({

            });

            var UploadFile = new FormData();
            UploadFile.append("Param", productid)


            var url = this.HomeUrl + "api/Azole/Packs_basedon_productId";
            var accessToken = this.token; 

         
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`
            });

         
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                    

                    this.Packsdeta = data;
                },
                (err) => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }


    Addtocart(Products: any, item: any) {
        debugger
        if (this.loginDetails==null) {
            // If the user is not logged in, show a warning and redirect to the login page
            this.generalService.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'You are not logged in. Please login', 'Warning');
            this.router.navigate(['/Login']);
            return; // Exit the function early
        }

        // Check if the item is already in the cart
        const existingItem = this.CartService.cartItemList.find(cartItem => cartItem.PackageId === item.PackageId);
        if (existingItem) {
            this.generalService.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'This item is already in the cart.', '');
            return;
        }

        // Calculate the package price based on the discount
        if (this.loginDetails[0].Discount == null) {
            this.packageprice = item.PackPrice;
        } else {
            let totalWithGST = item.PackPrice;
            this.packageprice = totalWithGST - (item.PackPrice * this.discus[0].Discount / 100);
        }

        // Add the selected item to the cart
        this.selectedItemforCart.push({
            ProductId: Products.ProductId,
            ProductName: Products.Name,
            ZBookingID: Products.Books_ID,
            Record_Image: Products.Record_Image,
            CatalogNo: Products.Catalogue_Number,
            Price: this.packageprice,
            BasePrice: item.PackPrice,
            PackSize: item.PackName,
            HSNCode: Products.HSN,
            CAS: Products.CAS_No,
            ChemicalName: Products.Name,
            GSTTAX: Products.Gst,
            ItemCode: Products.Item_Code,
            PackageId: item.PackageId,
            Quantity: item.Quantity,
            VariantID: item.variant_item_id,
            CRMID: this.CRMID,
            Books_ID: this.Books_ID,
            Availability: item.Availability
        });

        // Add the item to the cart using CartService
        this.CartService.addtoCart(this.selectedItemforCart[0]);

        // Show a success alert
        this.generalService.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Added to Cart Successfully.', '');

        // Clear the selected item for the cart
        this.selectedItemforCart = [];
    }

    insertCartItems(cartItems) {

        this.Cartimesforloginusers = []
        this.Cartimesforloginusers.push(cartItems)
        cartItems[0].UserId = String(this.loginDetails.ID)
        var url = "api/Azole/Insert_Customer_Orders";
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(cartItems));
        this.generalService.PostData(url, UploadFile).then((data: any) => {

            if (data != null) {

                var object = data.filter(x => x.Productid == cartItems[0].Productid)[0];
                this.CartService.addtoCart(object);
            }
        })
    }


    loading: boolean = false;
    loading1: boolean = false;
    loading2: boolean = false;
    Get_stocks(BookID: any) {
        this.loading = true; // Start loading
        var BookID = BookID;
        var UploadFile = new FormData();
        UploadFile.append("Param", BookID);
        var url = "api/Azole/GetStock_AvailabilityBasedOn_BookId";
        this.generalService.PostData(url, UploadFile).then(data => {
            this.Stockdata = data;
            this.sadata = [];
            debugger
            if (this.Stockdata.response.code === 0) {
                this.Stockdata.response.data.forEach(stockItem => {
                    this.Packsdeta.forEach(packItem => {
                        if (stockItem.variant_item_id === packItem.variant_item_id) {
                            let availability = stockItem.available_stock === 0 ? 'NA' : stockItem.available_stock;

                            this.sadata.push({
                                variant_item_id: stockItem.variant_item_id,
                                Availability: availability,
                                PackageId: packItem.PackageId,
                                PackName: packItem.PackName,
                                PackPrice: packItem.PackPrice,
                                Quantity: packItem.Quantity,
                                stockFetched: true // Mark stock as fetched
                            });
                        }
                    });
                });
            } else if (this.Stockdata.response.code == -1) {

               
                this.Packsdeta.forEach(packItem => {
                    let availability = packItem.available_stock === undefined ? 'NA' : packItem.available_stock;
                           debugger
                           this.sadata.push({
                             
                                 Availability: availability,
                                PackageId: packItem.PackageId,
                                PackName: packItem.PackName,
                                PackPrice: packItem.PackPrice,
                                Quantity: packItem.Quantity,
                                stockFetched: true // Mark stock as fetched

                            });
                        });
                    
                
            }

            if (this.Stockdata.response.code !== 0) {
                this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please place a custom enquiry for this stock', 'Warning');
            }
            this.loading = false; // Stop loading
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
            this.loading = false; // Stop loading in case of error
        });
    }



    GetProductsByCategory(categoryID: number) {

        this.activeCategoryID = categoryID;
        var UploadFile = new FormData();
        UploadFile.append("Param1", categoryID.toString());
        var url = "api/Azole/Get_Productsbycat";
        this.generalService.PostData(url, UploadFile).then(data => {

            this.category1 = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });

    }

   
    checkAvailability(item) {
     
        this.stockFetched = true;
    }

    getRandomAvailability() {
        
        return Math.floor(Math.random() * 10) + 1;
    }

    GetProductsByCategory1(CategoryID: any) {

        this.router.navigate(['/Products'], { queryParams: { categoryID: CategoryID } });
    }

    InsertBulkorders(Quantity1: string, UOM: string, Comments: string) {
        this.isButtonDisabled = true;
        if (!Quantity1 || !UOM) {
            this.generalService.ShowAlert('Warning', 'Quantity and UOM are required.', 'Warning');
            return;
        }

        this.arr = [{
            ENQ_ID: this.ENQ_ID,
            PersonID: this.loginDetails[0].RegId,
            Quantity: Quantity1,
            UOMID: this.UOM.UOMID,
            Comments: Comments,
            Name: this.productDetails[0].Name,
            Catalogue_Number: this.productDetails[0].Catalogue_Number,
        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');

        var url = 'api/Azole/BulkOrders_Crud';

        this.generalService.PostData(url, uploadfile).then((data:any) => {
                const response = JSON.parse(data);
            if (response.Status === "SUCCESS") {
                const enqId = response.ENQ_ID;

                if (this.flag == 1) {
                   
                } else {
                    this.generalService.Alertsuccess('<img src="../../../assets/icons/icons8-success.gif" />', 'Bulk order placed, email successfully dispatched. ✔️', '');

                    this.send_Mail(this.arr[0], enqId);
                    this.router.navigate(['/MyOrders'], { queryParams: { selectedTab: 'enquiries' } });
                    this.isButtonDisabled = false;
                }
            } else {
                this.generalService.ShowAlert("Error", 'Failed to add the order. Please try again later.', 'error');
                this.isButtonDisabled = false;
            }
        }).catch(error => {
            console.error("Error:", error);
         
            this.generalService.ShowAlert("Error", 'An unexpected error occurred. Please try again later.', 'error');
        });
    }

    toggleCustomEnquiry() {
         this.showCustomEnquiry = !this.showCustomEnquiry;
    }

    send_Mail(bulkOrderData: any, enqId: string) {
        const arr = [{
            ENQ_ID: enqId,
            EMailID: this.loginDetails[0].EMailID,
            UserName: this.loginDetails[0].UserName,
            Phone1: this.loginDetails[0].Phone1,
            Quantity: bulkOrderData.Quantity,
            PackSize: this.UOM.UOM,
            Comments: bulkOrderData.Comments,
            ItemName: bulkOrderData.Name,
            CasNumber: this.productDetails[0].CAS_No,

        }];

        const uploadFile = new FormData();
        uploadFile.append("Param", JSON.stringify(arr));
        const url = "api/Azole/Customer_Enquiry";

        this.generalService.PostData(url, uploadFile).then((data: any) => {
       
        });
    }



    GetUOM() {

        this.arr = [];
        this.arr.push({});

        var url = "api/Azole/GET_UOM";
        this.generalService.GetData(url).then(data => {

            this.uomlist = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    searchCont1(event) {

        let filtered: any[] = [];
        let query = event.query.toLowerCase();
        this.deptList = this.uomlist.filter(country =>
            country.UOM.toLowerCase().indexOf(query) === 0
        );
    }

    getCategoryNameFromLocalStorage(): string | null {
      
        return window.localStorage.getItem('cat');
    }

    decreaseQuantity(item) {
        if (item.Quantity > 1) {
            item.Quantity--;
        }
    }

    increaseQuantity(item) {
        if (item.Quantity < 10 && item.Quantity < item.Availability) {
            item.Quantity++;
        }
    }
    

    GetCOA() {

        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
                this.sendCOARequest();
            } else {
                this.generalService.ShowAlert('ERROR', 'Token not received', 'error');
            }
        }).catch(error => {
            console.error('Error fetching token:', error);
            this.generalService.ShowAlert('ERROR', 'Failed to fetch token', 'error');
        });
    }

    private sendCOARequest() {

        this.arr = [];
        this.arr.push({});

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');

        var url = this.HomeUrl + "api/Azole/COA_Curd";
        var accessToken = this.token; 
       
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${accessToken}`
        });

     
        this.http.post(url, UploadFile, { headers }).subscribe(

            (data: any) => {

                this.COApdf = data; 

                this.COApdfFiltered = this.COApdf.filter(coa => coa.Productid === this.ProductId);
                if (this.COApdfFiltered.length > 0) {

                    this.displayCOADropdown = true;
                } else {
                    
                    this.displayCOADropdown = false;
                }
            },
            (err) => {
                console.error('COA request failed:', err);
                this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
            });
    }

    async GetSafetydata(catalogueNumber: string) {
        debugger
        try {
            
            this.loading1 = true;
         
            // Check if ZOHO_Books_ID is present
            if (!this.productDetails[0].CRMID) {
                console.log('ZOHO_Books_ID is null or undefined');
                this.generalService.ShowAlert('INFO', 'MSDS will be coming soon', 'info');
                return;  // Exit the function if ZOHO_Books_ID is not available
            }
            if (this.MSDS_Status == false) {
                this.generalService.ShowAlert('INFO', 'MSID will coming soon', 'info');
                this.loading1 = false;  // Ensure loading indicator is turned off
                return;  // Exit the function if MSDS_Status is false

            }

            console.log('ZOHO_Books_ID found:', this.productDetails[0].CRMID);

            // Proceed with fetching the data
            const uploadFile = new FormData();
            const url = `api/Azole/Download_MSDS_Doc?recordId=${this.productDetails[0].CRMID}`;
            const data = await this.generalService.PostData(url, uploadFile);

            console.log('Data fetched:', data);

            this.base64path = data;
            this.loading1 = false;
            const binaryBuffer = this.base64ToBuffer(this.base64path);
            
            const blob = new Blob([binaryBuffer], { type: 'application/pdf' });
            
            this.Surl = URL.createObjectURL(blob);
            window.open(this.Surl, '_blank');
            
        } catch (error) {
            console.error('Error occurred:', error);
           // this.loading1 = false;
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        }
    }



    async GetSampleCOA(Sample_COA_Record_ID: any) {
        debugger
        if (!Sample_COA_Record_ID) {
            this.generalService.ShowAlert('INFO', 'Sample COA is generating soon', 'info');
            return;
        }
        try {
            this.loading2 = true;
          
            const uploadFile = new FormData();           
            uploadFile.append("Bookid", Sample_COA_Record_ID);
            const url = "api/Azole/Download_Sampe_COA_Doc";
            const data = await this.generalService.PostData(url, uploadFile);
            
            this.base64path = data;
            this.loading2 = false;
            const binaryBuffer = this.base64ToBuffer(this.base64path);
            this.showLoader();
            const blob = new Blob([binaryBuffer], { type: 'application/pdf' });
            debugger
         
            this.Surl = URL.createObjectURL(blob);

            window.open(this.Surl, '_blank');
            this.hideLoader();
        } catch (error) {
            this.hideLoader();
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        }
    }

    base64ToBuffer(base64String: string): Uint8Array {
        debugger
        const binaryString = atob(base64String);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    showLoader() {
        document.getElementById('loader').style.display = 'block';
    }

    // Function to hide loader
    hideLoader() {
        document.getElementById('loader').style.display = 'none';
    }
    async GetCOApdf(COA: string) {

        try {
            const uploadFile = new FormData();
            const url = `api/Azole/GetCOAPDF?COA=${COA}`;
            const data = await this.generalService.PostData(url, uploadFile);
            const relativeFilePath = data;
            const fullFilePath = this.HomeURL + '/' + relativeFilePath;
            window.open(fullFilePath, '_blank');
        } catch (error) {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        }
    }
    
    AddFav(value: any, item: any) {
        if (this.loginDetails[0] != 0) {
            if (!value.Catalogue_Number) {
                console.error("Catalogue_Number is undefined");
                return; // Exit the function early if Catalogue_Number is undefined
            }

            this.arr = [];
            this.arr.push({
                RegId: this.loginDetails[0]?.RegId, // Use optional chaining to avoid errors if loginDetails[0] is undefined
                PackageId: item.PackageId,
                Catalog_No: value.Catalogue_Number,
                Catalogue_Number: value.Catalogue_Number,
                HSN: value.HSN,
                CAS_No: value.CAS_No,
                ProductId: value.ProductId, // Access ProductId directly from Catalogue_Number,CAS_No
                // Name: value.Catalogue_Number.Name,
                CatalogNo: value.Catalogue_Number.Catalogue_Number,
                Record_Image: this.productDetails[0].Record_Image,
                Name: this.productDetails[0].Name,
                Price: this.Price,
                PackPrice: item.PackPrice,
                PackName: item.PackName,
                HSNCode: value.Catalogue_Number.HSN,
                CAS: value.Catalogue_Number.CAS_No,
                Quantity: item.Quantity,
                CRMID: this.CRMID,
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '1');

            var url = this.HomeUrl + "api/Azole/Favourite_CRUD";
            var accessToken = this.token;

            this.http.post(url, UploadFile).subscribe((data: any) => {
                if (data == "SUCCESS") {
                    this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-success.gif" />', 'Product added to favourites successfully!', '');
                    // this.router.navigate(['/Favourite']);
                } else {
                    this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Product already added to favourites', 'error');
                }
            });
        } else {
            // this.generalService.ShowAlert("Warning", 'Account Disabled, Please Contact to Admin.', 'Warning');
        }
    }

   


    SearchandDownloadCOA(BatchNo: any) {
        
        var url = "api/Azole/SearchandDownloadCOA?BatchNo=" + BatchNo;
        this.generalService.GetData(url).then((data: any) => {
            
            if (data != "") {
                var COAResult = JSON.parse(data);
                if (COAResult.response.message == "Success") {
                    var COA = COAResult.response.downloadUrl
                    window.open(COA, '_blank');
                } else if (COAResult.response.message == "No records found for the given criteria.") {
                    this.generalService.ShowAlert('Alert', 'No records found for the given criteria.', 'error');
                }
            }
        })
    }


    GetFav() {
        
        if (this.loginDetails != undefined) {
            this.arr = [];
            this.arr.push({
                RegId: this.loginDetails[0].RegId
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = "api/Azole/Favourite_CRUD";

            this.generalService.PostData(url, UploadFile).then(data => {
                

                this.favlist = data;
                this.FavorateItem = this.favlist.some(item => item.Catalogue_Number === this.Catalogue_Number);
            },
                err => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        }
        else {
           // this.generalService.ShowAlert("Warning", 'Account Disabled, Please Contact to Admin.', 'Warning');

        }
        }
        // Call Gettoken and await its completion

        


}


