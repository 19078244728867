import { Component } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent {

    messages: { text: string }[] = [];
    userMessage: string = '';
    isChatOpen: boolean = false;

    // Function to send a message
    sendMessage(): void {
        if (this.userMessage.trim()) {
            this.messages.push({ text: this.userMessage });
            this.userMessage = '';
            this.scrollToBottom();
        }
    }

    // Function to toggle the chat window visibility
    toggleChat(): void {
        this.isChatOpen = !this.isChatOpen;
    }

    // Function to automatically scroll to the bottom of the chat messages
    scrollToBottom(): void {
        setTimeout(() => {
            const chatMessagesContainer = document.querySelector('.chatbot-messages') as HTMLElement;
            chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
        }, 100);
    }
}
