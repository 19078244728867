import { Component } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../../seo.service'
@Component({
  selector: 'app-useraddress',
  templateUrl: './useraddress.component.html',
  styleUrls: ['./useraddress.component.scss']
})
export class UseraddressComponent {
    // public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    profiledata: any;
    Citydata: any = [];
    Name: any;
    mobile: any;
    HNo: any;
    Address: any;
    Landmark: any;
    City: any;
    State: any;
    Pincode: any;
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    LoginDetails1: any; LoginDetails: any;
    // selectedpast: string;
    Lastdonatedate: any;
    Status: boolean = true;
    Mobile: any;
    RegID: any;
    logindata: boolean;
    schooldata: any = [];
    category: any = [];
    subtasklist: any;
    ButtonText: any;
    selectedOption: any;
    ID: any;
    users: any;
    ddd: any;

    constructor(private seoService: SeoService,public genralservice: GeneralService, public http: HttpClient, public fb: FormBuilder, public router: Router,) {

         
        localStorage.removeItem("keepsignin");
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
       
        if (this.LoginDetails != null) {
            this.logindata = true;
            this.Name = this.LoginDetails[0].Name
            this.mobile = this.LoginDetails[0].Mobile
         
            this.logindata = false;
        }
    }

    ngOnInit(): void {
        // this.getschool();
        this.seoService.updateMetaTags2('Address', '');
        this.getregdetails();
    }
    


    getregdetails() {
         
        this.arr = []
        this.arr.push({

        })
        var UploadFile = new FormData()
        UploadFile.append("Param1", this.LoginDetails[0].RegId);
        UploadFile.append("Param2", '1');
        var url = "api/Azole/Addressfilterbasedonuid";
        this.genralservice.PostData(url, UploadFile).then(data => { 
            this.category = data;



        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }



    getSelectedschool(val: any) {

        this.ID = val
    }
   

    Back() {
        this.router.navigate(['/']);
    }


    plus() {
        this.router.navigate(['/Address']);
    }
    edit(AID: string, State: string) {
        this.router.navigate(['/Address', { addid: AID}]);
    }
    
    deleteRecord(AID: any) {
        if (confirm('Are you sure you want to delete this address?')) {
            const url = 'api/Azole/Address_Master'; // Change this URL to your delete API endpoint
            const formData = new FormData();
            formData.append("Param", JSON.stringify([{ AID: AID }])); // Assuming AID is the Assessment ID
            formData.append("Flag", '3');

            this.genralservice.PostData(url, formData).then(data => {
                if (data === 'SUCCESS') {
                    this.genralservice.ShowAlert("SUCCESS", 'Address deleted successfully', 'success');
                    // Optionally, perform any additional actions upon successful deletion
                    this.getregdetails();
                    window.location.reload()// Refresh data after deletion
                } else {
                    // Handle error response if necessary
                    console.error('Error deleting address:', data);
                    this.genralservice.ShowAlert("ERROR", 'Failed to delete address', 'error');
                }
            }).catch(error => {
                // Handle HTTP request error if necessary
                console.error('HTTP request error:', error);
                this.genralservice.ShowAlert("ERROR", 'Failed to delete address', 'error');
            });
        }
    }



}

